import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchDairyStore = defineStore('searchDairyStore', {
  state: () => ({
    Dairy_ident: null as number | null,
    Dairy_berNo: null as number | null,
    Dairy_dairyTypeId: null as number | null,
    Dairy_iban: null as number | null,
    Dairy_sapNo: null as number | null,
    Dairy_permitNo: null as number | null,
    Dairy_personId: null as number | null,
    Dairy_responsibilityGroup: null as number | null,
    Dairy_tsmOffice: null as number | null,
    Dairy_status: null as number | null,
    Dairy_cantonId: null as number | null,
    Dairy_productionReportingObligation: null as number | null,
    Dairy_processingReportingObligation: null as number | null,
    Dairy_segmentationReportingObligation: null as number | null,
    validUntil: null as string | null,
    useTerms: true,
    items: [
      { key: 'Dairy_ident', operation: ':', itemType: 'string', isList: true },
      { key: 'Dairy_berNo', operation: ':' },
      { key: 'Dairy_dairyTypeId', operation: ':' },
      { key: 'Dairy_iban', operation: ':' },
      { key: 'Dairy_sapNo', operation: ':' },
      { key: 'Dairy_permitNo', operation: ':' },
      { key: 'Dairy_personId', operation: ':' },
      { key: 'Dairy_responsibilityGroup', operation: ':' },
      { key: 'Dairy_tsmOffice', operation: ':' },
      { key: 'Dairy_status', operation: ':', itemType: 'number' },
      { key: 'Dairy_cantonId', operation: ':' },
      { key: 'Dairy_productionReportingObligation', operation: ':' },
      { key: 'Dairy_processingReportingObligation', operation: ':' },
      { key: 'Dairy_segmentationReportingObligation', operation: ':' },
      { key: 'validUntil', operation: ':' }
    ] as TermItemDefinition[]
  })
})
